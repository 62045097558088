import { Flex, Text, Link } from '@chakra-ui/react';
import React from 'react';

const Footer = () => {
  return (
    <Flex
      w="full"
      bg="blackAlpha.50"
      minHeight="20vh"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
    >
      <Text mb="3">
        Make with ❤️ by{' '}
        <Link href="https://www.linkedin.com/in/stephenharrison/" isExternal color="red">
          Stephen Harrison
        </Link>
      </Text>
    </Flex>
  );
};

export default Footer;