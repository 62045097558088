import {
  Box,
  Button,
  Flex,
  Img,
  Spacer,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import chakraHero from "../assets/MAGBA.png";

const Hero = () => {
  const [isLargerThanLG] = useMediaQuery("(min-width: 62em)");
  return (
    <Flex
      alignItems="center"
      w="full"
      px={isLargerThanLG ? "16" : "6"}
      py="16"
      minHeight="90vh"
      justifyContent="space-between"
      flexDirection={isLargerThanLG ? "row" : "column"}
    >
      <Box mr={isLargerThanLG ? "6" : "0"} w={isLargerThanLG ? "60%" : "full"}>
        <Text
          fontSize={isLargerThanLG ? "5xl" : "4xl"}
          fontWeight="bold"
          mb="4"
        >
          {" "}
          Join the Movement!
        </Text>

        <Text mb="6" fontSize={isLargerThanLG ? "lg" : "base"} opacity={0.7}>
          Are you a Limey feeling lonely? Regret giving your American friends
          the boot? Racked with guilt for leaving it so long to get back in
          touch? Uncertain they'll have you back?
          <br />
          Then this is the movement you can get behind.
        </Text>

        <Button
          w="200px"
          colorScheme="red"
          variant="solid"
          h="50px"
          size={isLargerThanLG ? "lg" : "md"}
          mb={isLargerThanLG ? "0" : "10"}
          onClick={(e) => {
            e.preventDefault();
            window.location.href =
              "https://store.covver.io/m-a-g-britain-a/collections/m-a-g-britain-a-swag-store";
          }}
        >
          Merch!
        </Button>
      </Box>
      <Spacer />
      <Flex
        w={isLargerThanLG ? "40%" : "full"}
        alignItems="center"
        justifyContent="center"
      >
        <Img src={chakraHero} alt="Chakra UI" />
      </Flex>
    </Flex>
  );
};

export default Hero;
