import { Box, useDisclosure } from '@chakra-ui/react';
import React, { useRef } from 'react';
import DrawerComponent from './components/DrawerComponent';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Nav from './components/Nav';

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <Box>
      <Nav ref={btnRef} onOpen={onOpen} />
      <Hero />
      <Footer />
    </Box>
  );
}

export default App;